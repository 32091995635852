import React, { useEffect } from "react";
import { Box, Card, Typography, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate } from "react-router-dom";
import Form from "../../shared/components/Form";
import Analytics from "../../shared/services/analytics";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 15px 30px",
  },
  card: {
    maxWidth: "400px",
  },
}));

const Register = ({ setUsers, api }) => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();

  const fields = [
    { name: "firstName", label: "First Name", required: true },
    { name: "lastName", label: "Last Name", required: true },
    {
      name: "username",
      label: "Username",
      required: true,
      validate: async (v, data) =>
        (await api.isUsernameValid(v)) || "Username is already taken",
    },
    { name: "email", label: "Email", required: true, type: "email" },
    { name: "password", label: "Password", required: true, type: "password" },
    {
      name: "passwordConfirmation",
      label: "Repeat Password",
      required: true,
      type: "password",
      validate: (v, data) =>
        v === data?.password || "The passwords do not match",
    },
  ];

  useEffect(() => {
    Analytics.viewPage("register");
  }, []);

  const registerUser = (data) => {
    api
      .register(
        data.firstName,
        data.lastName,
        data.username,
        data.email,
        data.password
      )
      .then((user) => {
        setUsers((prevUsers) => [...prevUsers, user]);
        Analytics.registerUser(user);
        state?.previousRoute
          ? navigate(state.previousRoute, {
              state,
            })
          : navigate("/account");
      });
  };

  return (
    <Box container className={classes.container}>
      <Card variant="outlined" className={classes.card}>
        <CardContent>
          <Typography variant="h6" component="h1" textAlign="center" mb={2}>
            Create your account
          </Typography>
          <Form
            fields={fields}
            onSubmit={registerUser}
            submitButtonText={"Sign Up"}
          />
          <Typography variant="body1" component="div" align="center" mt={2}>
            Already have an account? Login{" "}
            <Link to="/login" state={state}>
              here
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Register;
