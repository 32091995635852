import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import ShoeCard from "./ShoeCard";
import theme, { useThemeStyles } from "../../app/theme";

const useStyles = makeStyles((theme) => ({
  heroContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "261px",
    paddingTop: "33px",
    background:
      "linear-gradient(130deg, #D6DAFF 0.41%, #FFD6E0 63.61%, #C5E7FC 100%)",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  shoeListHeader: {
    padding: "18px 0 0",
  },
  shoeListTypeContainer: {
    display: "flex",
  },
  arrowContainer: {
    position: "relative",
  },
  arrowBuy: {
    position: "absolute",
    top: "-17px",
    left: "165px",
  },
  arrowSell: {
    position: "absolute",
    top: "-17px",
    left: "205px",
  },
  results: {
    width: "130px",
    textAlign: "right",
  },
}));

const ShoeList = ({ shoes, shoeOptions }) => {
  const classes = useStyles();
  const themeClasses = useThemeStyles();

  const [shoeListType, setShoeListType] = useState("buy");
  const toggleShoeListType = () => {
    setShoeListType(shoeListType === "buy" ? "sell" : "buy");
  };
  const [searchValue, setSearchValue] = useState("");

  const filteredShoes = shoes.filter((shoe) => {
    let selectShoe = shoe.requested === (shoeListType === "sell");
    const keysToIgnore = [
      "id",
      "images",
      "hasOrder",
      "order",
      "requested",
      "seller",
      "timeCreated",
      "timeModified",
    ];

    if (searchValue) {
      let found = false;
      const search = searchValue.toLowerCase();
      for (const key of Object.keys(shoe)) {
        if (keysToIgnore.includes(key)) continue;
        let value = shoe[key]?.toString();
        if (shoeOptions.hasOptions(key)) {
          // This ensure we search using the label rather than value
          value = shoeOptions._getLabel(key, value);
        }
        found = found || value.toLowerCase().includes(search);
        if (found) break;
      }
      selectShoe = selectShoe && found;
    }

    return selectShoe;
  });

  return (
    <>
      <Box className={classes.heroContainer}>
        <Box className={themeClasses.responsiveWidth}>
          <Typography variant="h1" component="p">
            Stop throwing money away.
          </Typography>
          <Typography variant="subtitle1">
            Earn £20+ selling single skate shoes
          </Typography>
          <Button variant="contained" href="/sell" sx={{ marginTop: "16px" }}>
            Sell a shoe
          </Button>
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={themeClasses.responsiveWidth}>
          <Box className={classes.shoeListHeader}>
            <Box className={classes.shoeListTypeContainer}>
              <Typography variant="h1" component="p">
                I'm looking to{" "}
              </Typography>
              <Button
                sx={{
                  padding: 0,
                  fontSize: "25px",
                  fontWeight: 800,
                  color: theme.palette.orange.main,
                  lineHeight: 1.167,
                  textTransform: "none",
                  borderBottom: `2px dashed ${theme.palette.orange.main}`,
                  borderRadius: 0,
                  marginLeft: "6px",
                  minWidth: 0,
                  zIndex: 1,
                  ":hover": {
                    borderBottom: `2px solid ${theme.palette.orangeHover.main}`,
                    color: theme.palette.orangeHover.main,
                  },
                }}
                onClick={() => toggleShoeListType()}
              >
                {shoeListType}
              </Button>
            </Box>
            <Box className={classes.arrowContainer}>
              <img
                src="/arrow-buy.svg"
                alt="arrow for styling"
                className={classes.arrowBuy}
                hidden={shoeListType !== "buy"}
              />
              <img
                src="/arrow-sell.svg"
                alt="arrow for styling"
                className={classes.arrowSell}
                hidden={shoeListType !== "sell"}
              />
            </Box>
            <Typography variant="h2" component="h1" mt={6}>
              {shoeListType === "buy" ? "Shoes on sale" : "Requested shoes"}
            </Typography>
            <TextField
              placeholder="Find a shoe..."
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="primary" />
                  </InputAdornment>
                ),
                ...(searchValue
                  ? {
                      endAdornment: (
                        <Typography
                          variant="body1"
                          color="grey.main"
                          className={classes.results}
                        >
                          {filteredShoes.length} results
                        </Typography>
                      ),
                    }
                  : {}),
              }}
              /* TODO: Make this part of theme style? */
              sx={{ width: "100%", marginTop: "25px" }}
            />
          </Box>
          <Grid container item columnSpacing={2} rowSpacing={2} mt={0}>
            {filteredShoes.map((shoe) => (
              <ShoeCard key={shoe.id} shoe={shoe} shoeOptions={shoeOptions} />
            ))}
          </Grid>
          <Typography variant="h2" component="p" mt={5} mb={2}>
            Didn't find what you're looking for?
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            href={shoeListType === "buy" ? "/request-shoe" : "/sell"}
          >
            {shoeListType === "buy" ? "Request a shoe" : "Sell a shoe"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ShoeList;
