import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Typography, Box, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import theme from "../theme";
import Analytics from "../../shared/services/analytics";
import { useQuery } from "../../shared/utils/navigation";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    position: "relative",
    minHeight: "100vh",
  },
  contentWrap: {
    paddingBottom: "2.5rem",
  },
  outletWrapper: {
    paddingTop: "60px",
  },
}));

function AppContainer({ appReady, user, api }) {
  const classes = useStyles();
  const query = useQuery();

  useEffect(() => {
    if (appReady) {
      Analytics.land(query);
    }
  }, [appReady]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Box
          className={classes.pageContainer}
          sx={{ backgroundColor: theme.palette.background.main }}
        >
          <Box className={classes.contentWrap}>
            <NavigationBar user={user} api={api} />
            <Box className={classes.outletWrapper}>
              {appReady ? (
                <Outlet />
              ) : (
                <Typography mt={5} textAlign="center">
                  Just getting ready...
                </Typography>
              )}
            </Box>
          </Box>
          <Footer />
        </Box>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default AppContainer;
