import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ImageGrid from "./ImageGrid";
import Analytics from "../../shared/services/analytics";

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "30px",
  },
}));

const ShoeDetail = ({ shoes, users, shoeOptions, api, user }) => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const shoe = shoes.filter((shoe) => shoe.id === parseInt(id)).pop();
  const seller = shoe
    ? users.filter((user) => user.id === shoe.seller).pop()
    : undefined;

  const [selectedImage, setSelectedImage] = useState(0);

  useEffect(() => {
    if (shoe) {
      Analytics.viewShoe(shoe);
    }
  }, [shoe]);

  useEffect(() => {
    if (shoe == null) {
      navigate("/");
      return;
    }

    setLoading(false);
  });

  const handleBuy = () => {
    if (!user || !api) {
      navigate("/login", {
        state: {
          previousRoute: `/shoes/${id}`,
        },
      });
      return;
    }
    navigate(`/checkout/${id}`);
  };

  return (
    <>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          minHeight="80vh"
        >
          <Grid item xs={12} my={2}>
            <Typography variant="body1">Just a second...</Typography>
          </Grid>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Container maxWidth="xl">
          <Grid container className={classes.container}>
            <Grid item container xs={11} xl={10} spacing={3}>
              <Grid item xs={12} md={6}>
                <img
                  width="100%"
                  src={shoe.images?.[selectedImage]?.image}
                  alt="skate shoe"
                ></img>
                {shoe.images && shoe.images.length > 1 ? (
                  <ImageGrid
                    images={shoe.images}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography
                  variant="h3"
                  component="div"
                  textTransform="uppercase"
                >
                  {shoeOptions?.getBrandLabel(shoe.brand)}
                </Typography>
                <Typography
                  variant="h4"
                  component="div"
                  textTransform="uppercase"
                >
                  {shoe.icon}
                </Typography>
                <Box mb={2}>
                  <Typography variant="subtitle1" component="div" gutterBottom>
                    Sold by:{" "}
                    <Link to={`/seller/${seller?.username}`}>
                      <b>{seller?.username}</b>
                    </Link>
                  </Typography>
                </Box>
                <Typography variant="title1">
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <b>Details</b>{" "}
                    {shoe.requested ? (
                      <Typography color="secondary">
                        <b>Wanted</b>
                      </Typography>
                    ) : null}
                  </Typography>
                  <hr />
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    mt={1}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Shoe type: <b>{shoeOptions?.getGenderLabel(shoe.gender)}</b>
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    mt={1}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Shoe: <b>{shoeOptions?.getFootLabel(shoe.foot)}</b>
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Size: <b>{shoeOptions?.getSizeLabel(shoe.size)} UK</b>
                  </Typography>
                </Typography>
                <Typography
                  variant="title1"
                  component="div"
                  gutterBottom
                  mt={2}
                >
                  <b>Description</b>
                  <hr />
                  <Typography variant="body1" mt={1}>
                    {shoe.condition}
                  </Typography>
                </Typography>
                <Typography
                  mt={2}
                  variant="h4"
                  component="div"
                  gutterBottom
                  sx={{ display: "flex", justifyContent: "start" }}
                >
                  <b>£{shoe.price}</b>
                </Typography>

                {user?.id === shoe.seller ? (
                  shoe.hasOrder ? (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={true}
                      sx={{ width: "100%" }}
                    >
                      Sold
                    </Button>
                  ) : null
                ) : shoe.requested ? (
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ width: "100%" }}
                    onClick={() => navigate("/sell")}
                  >
                    Sell
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={shoe.hasOrder}
                    sx={{ width: "100%" }}
                    onClick={handleBuy}
                  >
                    {shoe.hasOrder ? "Sold" : "Buy"}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      ;
    </>
  );
};

export default ShoeDetail;
