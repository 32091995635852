import React, { useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@material-ui/core";
import { Menu } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Analytics from "../../shared/services/analytics";

const NavigationDrawer = ({ user, api }) => {
  const [open, setOpen] = useState(false);
  const items = [
    { name: "Shoes", component: "link", path: "" },
    { name: "Sell a shoe", component: "link", path: "sell" },
    { name: "Request a shoe", component: "link", path: "request-shoe" },
    { name: "profileDivider", component: "divider" },
  ];
  const navigate = useNavigate();

  if (user) {
    items.push({ name: "Profile", component: "link", path: "account" });
    items.push({
      name: "Logout",
      component: "button",
      onClick: () => {
        api.logout();
        Analytics.logout();
        navigate("/login");
      },
    });
  } else {
    items.push({ name: "Login", component: "link", path: "login" });
  }
  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Menu color="primary" />
      </IconButton>
      <Drawer
        open={open}
        anchor="right"
        BackdropProps={{ onClick: () => setOpen(false) }}
        PaperProps={{ sx: { backgroundColor: "white.main" } }}
      >
        <Box
          className={classes.drawer}
          role="presentation"
          onClick={() => setOpen(false)}
        >
          <List>
            {items.map((item) => {
              switch (item.component) {
                case "link":
                case "button":
                  return (
                    <ListItem
                      key={item.name}
                      className={
                        item.path !== undefined &&
                        location.pathname === `/${item.path}`
                          ? classes.activeItem
                          : classes.inactiveItem
                      }
                      disablePadding
                    >
                      <ListItemButton
                        {...(item.path !== undefined
                          ? { as: Link, to: item.path }
                          : {})}
                        {...(item.onClick ? { onClick: item.onClick } : {})}
                      >
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </ListItem>
                  );
                case "divider":
                  return <Divider key={item.name} />;
                default:
                  return null;
              }
            })}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 250,
    backgroundColor: theme.palette.white.main,
  },
  activeItem: {
    borderLeft: `${theme.palette.accent.main} 4px solid`,
    backgroundColor: "rgba(0,0,0,0.04)",
  },
  inactiveItem: {
    borderLeft: `rgba(0,0,0,0) 4px solid`,
  },
}));

export default NavigationDrawer;
