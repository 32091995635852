import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Container,
  Grid,
  CssBaseline,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import ShoeCard from "../../shoes/components/ShoeCard";
import Analytics from "../../shared/services/analytics";

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    margin: "30px 15px 30px",
  },
  shoeList: {
    padding: "20px 20px",
  },
  noShoes: {
    justifyContent: "center",
  },
}));

const UserProfile = ({
  type,
  users,
  user,
  shoes,
  shoeOptions,
  api,
  setShoes,
}) => {
  // TODO: Component needs a general cleanup
  // Only users or user will be specified:
  // if type = seller; users will be available
  // if type = me; user will be available

  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  if (type === "seller") {
    user = users
      ? users.filter((user) => user.username === params.username).pop()
      : undefined;
  }

  const userShoes = shoes
    ? shoes.filter((shoe) => shoe.seller === user?.id)
    : [];

  useEffect(() => {
    if (type === "me" && user === null) navigate("/login");
  });

  useEffect(() => {
    if (user) {
      Analytics.viewProfile(user);
    }
  }, [user]);

  return (
    <>
      <CssBaseline />
      <Container className={classes.container} maxWidth="sm">
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h1" textAlign="center">
                  {type === "me" ? "Your details" : "Seller details"}
                </Typography>
                {type === "me"
                  ? [
                      <Grid key="firstName" container my={2}>
                        <Grid key="label" item xs={6}>
                          <Typography variant="subtitle1">
                            <b>First Name</b>
                          </Typography>
                        </Grid>
                        <Grid key="value" item xs={6}>
                          {user?.firstName}
                        </Grid>
                      </Grid>,
                      <Grid key="lastName" container my={2}>
                        <Grid key="label" item xs={6}>
                          <Typography variant="subtitle1">
                            <b>Last Name</b>
                          </Typography>
                        </Grid>
                        <Grid key="value" item xs={6}>
                          {user?.lastName}
                        </Grid>
                      </Grid>,
                      <Grid key="email" container my={2}>
                        <Grid key="label" item xs={6}>
                          <Typography variant="subtitle1" display="block">
                            <b>Email</b>
                          </Typography>
                        </Grid>
                        <Grid key="value" item xs={6}>
                          {user?.email}
                        </Grid>
                      </Grid>,
                    ]
                  : null}
                <Grid key="username" container my={2}>
                  <Grid key="label" item xs={6}>
                    <Typography variant="subtitle1">
                      <b>Username</b>
                    </Typography>
                  </Grid>
                  <Grid key="value" item xs={6}>
                    {user?.username}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.shoeList} maxWidth="lg">
        <Typography variant="h5" align="center" paragraph>
          {type === "me" ? "Shoes you're selling" : "Shoes they're selling"}
        </Typography>
        <Grid container spacing={2}>
          {userShoes.length === 0 ? (
            type === "me" ? (
              <Grid item container className={classes.noShoes}>
                <Typography textAlign="center" minWidth={"100%"} mt={3} mb={2}>
                  One more step to start making money!
                </Typography>
                <Button
                  component={Link}
                  to="/sell"
                  variant="contained"
                  color="secondary"
                >
                  Sell a shoe
                </Button>
              </Grid>
            ) : (
              <Grid item container className={classes.noShoes}>
                <Typography textAlign="center" minWidth={"100%"} mt={3} mb={2}>
                  Looks like they haven't uploaded any shoes yet
                </Typography>
              </Grid>
            )
          ) : type === "me" ? (
            userShoes.map((shoe) => (
              <ShoeCard
                key={shoe.id}
                shoe={shoe}
                shoeOptions={shoeOptions}
                type="me"
                api={api}
                setShoes={setShoes}
              />
            ))
          ) : (
            userShoes.map((shoe) => (
              <ShoeCard key={shoe.id} shoe={shoe} shoeOptions={shoeOptions} />
            ))
          )}
        </Grid>
      </Container>
    </>
  );
};

export default UserProfile;
