import * as React from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
  Grid,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Analytics from "../../shared/services/analytics";

const ShoeCard = ({ shoe, shoeOptions, type, api, setShoes }) => {
  const defaultImage =
    shoe.images.filter((image) => image.default).pop() || shoe.images[0];

  const [open, setOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    handleDialogClose();
    api
      .deleteShoe(shoe.id)
      .then(() => {
        Analytics.deleteShoe(shoe);
        setShoes((prevState) =>
          prevState.filter((curShoe) => curShoe.id !== shoe.id)
        );
      })
      .catch((err) => {
        //TODO: add an <Alert/> comp in return body to convey this error to user
        console.log(err.message);
      });
  };

  const navigate = useNavigate();
  const handleEdit = () => {
    navigate(`/shoes/${shoe.id}/edit/`);
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        sx={{
          padding: "0px",
        }}
      >
        <CardActionArea LinkComponent={Link} to={`/shoes/${shoe.id}`}>
          <CardMedia
            component="img"
            height="220"
            src={defaultImage?.image}
            alt={`Image of ${shoe.brand} ${shoe.icon} shoe`}
          />
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle2" component="div">
                  {shoeOptions?.getBrandLabel(shoe.brand)}
                </Typography>
                <Typography variant="h3" component="div">
                  {shoe.icon}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  component="div"
                  textAlign="right"
                >
                  {shoeOptions?.getFootLabel(shoe.foot)} |{" "}
                  {shoeOptions?.getSizeLabel(shoe.size)} UK
                </Typography>
                <Typography variant="h3" component="div" textAlign="right">
                  £{shoe.price}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>

        {/* if on account page  */}
        {type === "me" && (
          <CardActions>
            <Button
              size="small"
              variant="contained"
              onClick={handleEdit}
              color="secondary"
            >
              Edit
            </Button>
            <Button size="small" variant="contained" onClick={handleDialogOpen}>
              Delete
            </Button>
            <Dialog open={open} onClose={handleDialogClose}>
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete this shoe?"}
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={handleDelete} color="accent">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};

export default ShoeCard;
