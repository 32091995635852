import { React, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import Analytics from "../../shared/services/analytics";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
    marginLeft: "5%",
    marginRight: "5%",
  },
}));

const Text = styled(Typography)({
  overflowWrap: "break-word",
  fontSize: "16px",
});

const OrderSuccessful = ({ user, shoes, shoeOptions }) => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const shoe = shoes.filter((shoe) => shoe.id === parseInt(id)).pop();

  useEffect(() => {
    if (shoe?.order === null || shoe.order.user != user.id) {
      navigate("/not-found");
      return;
    }

    setLoading(false);

    Analytics.successfulOrder(shoe);
  }, [shoe]);

  return (
    <>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          minHeight="80vh"
        >
          <Grid item xs={12} my={2}>
            <Typography variant="body1">Just a second...</Typography>
          </Grid>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Box className={classes.container}>
          <Typography variant="h4" textAlign="center">
            Payment Successful, hell yeh 😎
          </Typography>
          <Typography variant="body1" textAlign="center" mt={1} fontSize="17px">
            You should receive an email confirmation any second now...
          </Typography>
          <Container maxWidth="sm">
            <Typography variant="body1" mt={5}>
              Here's a summary of your order:
            </Typography>
            <Box border="0.1px solid" borderRadius="10px" mt={1}>
              <Grid container mt={2} mb={2}>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle1"
                    ml={3}
                    sx={{ fontWeight: 600 }}
                  >
                    Order details:
                  </Typography>
                  <Text mt={2} ml={3}>
                    <Link
                      to={`/shoes/${shoe.id}/`}
                    >{`${shoeOptions.getBrandLabel(shoe.brand)} ${
                      shoe.icon
                    } `}</Link>
                  </Text>
                  <Text ml={3}>{shoeOptions.getFootLabel(shoe.foot)} shoe</Text>
                  <Text ml={3}>Uk {shoe.size}</Text>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle1"
                    ml={3}
                    sx={{ fontWeight: 600 }}
                  >
                    Delivery:
                  </Typography>
                  <Text mt={2} ml={3}>
                    {shoe.order.shippingAddress.customerName}
                  </Text>
                  <Text ml={3}>{shoe.order.shippingAddress.addressLine1}</Text>
                  <Text ml={3}>{shoe.order.shippingAddress.addressLine2}</Text>
                  <Text ml={3}>{shoe.order.shippingAddress.postalCode}</Text>
                  <Text ml={3}>{shoe.order.shippingAddress.country}</Text>
                </Grid>
                <Grid item xs={12} mt={5}>
                  <Typography ml={3}>{`Subtotal: £${shoe.price}`}</Typography>
                  <Typography
                    ml={3}
                  >{`Buyer Protection Fee: £${shoe.order.buyerProtectionFee}`}</Typography>
                  <Typography
                    ml={3}
                  >{`Delivery: £${shoe.order.deliveryFee}`}</Typography>
                  <Typography
                    ml={3}
                    mt={1}
                    sx={{ fontWeight: 600, fontSize: "20px" }}
                  >
                    Order Total: £{shoe.order.totalPrice}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2} sx={{ width: "100%" }}>
              <Typography>
                You will recieve an email with the tracking number once the
                seller has sent the shoe.
              </Typography>
            </Box>
          </Container>
          <Typography mt={6} variant="h5">
            Thank you for using Switch ❤️
          </Typography>
          <Box mt={4}>
            <Button variant="contained">Keep Browsing</Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default OrderSuccessful;
