import * as React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useThemeStyles } from "../theme";
import NavigationDrawer from "./NavigationDrawer";

const useStyles = makeStyles((theme) => ({
  logo: {
    textDecoration: "unset",
  },
}));

const NavigationBar = ({ user, api }) => {
  const classes = useStyles();
  const themeClasses = useThemeStyles();

  return (
    <AppBar position="fixed" color="white">
      <Toolbar className={themeClasses.responsiveWidth}>
        <Typography
          as={Link}
          to="/"
          color="accent.main"
          fontWeight="800"
          fontFamily="Moirai One"
          fontSize="28px"
          lineHeight="normal"
          className={classes.logo}
        >
          switch
        </Typography>
        <Box flexGrow={1} />
        <NavigationDrawer user={user} api={api} />
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
