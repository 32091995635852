import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  selectedImg: {
    outlineWidth: 3,
    outlineColor: theme.palette.primary.main,
    outlineStyle: "solid",
  },
}));

const ImageGrid = ({ images, selectedImage, setSelectedImage }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      xs={12}
      flexDirection="row"
      spacing={1}
      mt={1}
      justifyContent="left"
    >
      {images.map((image, i) => (
        <Grid item key={i}>
          <img
            style={{
              cursor: "pointer",
              maxWidth: "100%",
              maxHeight: "120px",
            }}
            className={i === selectedImage ? [classes.selectedImg] : []}
            onClick={() => setSelectedImage(i)}
            src={image.image}
            alt="Shoe"
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ImageGrid;
