export class Options {
  constructor(shoeOptions) {
    this._shoeOptions = shoeOptions;

    Object.keys(shoeOptions).forEach((fieldName) => {
      // Dynamically generates methods like getBrandLabel
      // and getBrandValue for each option in shoeOptions
      this[fieldName] = shoeOptions[fieldName];

      const fieldNameTitleCase =
        fieldName.slice(0, 1).toUpperCase() + fieldName.slice(1);

      this[`get${fieldNameTitleCase}Label`] = (value) =>
        this._getLabel(fieldName, value);

      this[`get${fieldNameTitleCase}Value`] = (label) =>
        this._getValue(fieldName, label);
    });
  }

  hasOptions = (key) => Object.keys(this).includes(key);

  _getLabel = (field, value) => {
    const option = this[field].filter((opt) => opt.value === value).pop();
    return option.label;
  };

  _getValue = (field, label) => {
    const option = this[field].filter((opt) => opt.label === label).pop();
    return option.value;
  };
}
