import React, { useState, useEffect, useMemo } from "react";
import ShoeList from "../../shoes/components/ShoeList";
import ShoeDetail from "../../shoes/components/ShoeDetail";
import ShoeUpload from "../../shoes/components/ShoeUpload";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppContainer from "./AppContainer";
import Login from "../../profile/components/Login";
import Register from "../../profile/components/Register";
import UserProfile from "../../profile/components/UserProfile";
import API from "../../api";
import { Options } from "../../shoes/utils/options";
import NotFound from "./NotFound";
import Analytics from "../../shared/services/analytics";
import RequestShoe from "../../shoes/components/RequestShoe";
import OrderSuccessful from "../../payments/components/OrderSuccessful";
import Checkout from "../../payments/components/Checkout";

const App = () => {
  // App initialization state
  const [appReady, setAppReady] = useState(false);
  const [localStorageChecked, setLocalStorageChecked] = useState(false);

  // App data
  const [users, setUsers] = useState([]);
  const [shoes, setShoes] = useState([]);
  const [shoeOptions, setShoeOptions] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  // useMemo() is being used since some useEffect code below recalculates each
  // time api changes and since a new object is never the same as the object
  // before it results in an infinite loop
  const api = useMemo(
    () => new API(currentUser, setCurrentUser, setLocalStorageChecked),
    [currentUser]
  );

  useEffect(() => {
    const syncEffect = async () => {
      if (api && localStorageChecked) {
        await api.getUsers().then((users) => setUsers(users));
        await api.getShoes().then((shoes) => setShoes(shoes));
        await api
          .getShoeOptions()
          .then((shoeOptions) => setShoeOptions(new Options(shoeOptions)));
        Analytics.init();
        setAppReady(true);
      }
    };
    syncEffect();
  }, [api, localStorageChecked]);

  // Identify current user
  useEffect(() => {
    if (appReady && currentUser) {
      Analytics.identifyUser(currentUser);
    }
  }, [currentUser, appReady]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <AppContainer appReady={appReady} user={currentUser} api={api} />
          }
        >
          <Route
            index
            element={
              <ShoeList
                key="shoe-list"
                shoes={shoes}
                shoeOptions={shoeOptions}
              />
            }
          />
          <Route
            path="shoes/:id"
            element={
              <ShoeDetail
                key="shoe-detail"
                users={users}
                shoes={shoes}
                shoeOptions={shoeOptions}
                api={api}
                user={currentUser}
              />
            }
          />
          <Route
            path="sell"
            element={
              <ShoeUpload
                key="shoe-sell"
                user={currentUser}
                setShoes={setShoes}
                shoeOptions={shoeOptions}
                api={api}
              />
            }
          />
          <Route
            path="request-shoe"
            element={
              <RequestShoe
                key="request-shoe"
                user={currentUser}
                setShoes={setShoes}
                shoeOptions={shoeOptions}
                api={api}
              />
            }
          />
          <Route
            path="shoes/:id/edit/"
            element={
              <ShoeUpload
                key="shoe-edit"
                edit={true}
                user={currentUser}
                setShoes={setShoes}
                shoeOptions={shoeOptions}
                api={api}
                shoes={shoes}
              />
            }
          />
          <Route key="login" path="login" element={<Login api={api} />} />
          <Route
            key="register"
            path="register"
            element={<Register setUsers={setUsers} api={api} />}
          />
          <Route
            key="my-account"
            path="account"
            element={
              <UserProfile
                type="me"
                user={currentUser}
                shoes={shoes}
                shoeOptions={shoeOptions}
                api={api}
                setShoes={setShoes}
              />
            }
          />
          <Route
            path="seller/:username"
            element={
              <UserProfile
                key="seller-account"
                type="seller"
                users={users}
                shoes={shoes}
                shoeOptions={shoeOptions}
              />
            }
          />
          <Route
            path="order-successful/:id"
            element={
              <OrderSuccessful
                user={currentUser}
                shoes={shoes}
                shoeOptions={shoeOptions}
              />
            }
          />
          <Route
            path="checkout/:id"
            element={
              <Checkout
                user={currentUser}
                shoes={shoes}
                shoeOptions={shoeOptions}
                api={api}
              />
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
