import React from "react";
import { Typography, Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    justifyContent: "center",
  },
}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      minHeight="80vh"
    >
      <Typography variant="h1" align="center">
        Got lost?
      </Typography>
      <Typography variant="h4" align="center" mt={5}>
        Sorry, we can't find that page.
      </Typography>
      <Box className={classes.button} mt={5}>
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="secondary"
          size="large"
        >
          Home
        </Button>
      </Box>
    </Grid>
  );
};

export default NotFound;
