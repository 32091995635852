import mixpanel from "mixpanel-browser";

export const init = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    api_host: "https://switch-be.fly.dev/activities",
    debug: true,
  });
};

const _getUserProperties = (user) => ({
  $email: user.email,
  Username: user.username,
  $first_name: user.firstName,
  $last_name: user.lastName,
  "Is Staff": user.isStaff,
});

export const identifyUser = (user) => {
  // For users who registered before the analytics
  mixpanel.identify(user.id);
  mixpanel.people.set_once(_getUserProperties(user));
  mixpanel.track("Login");
};

export const registerUser = (user) => {
  mixpanel.identify(user.id);
  mixpanel.people.set(_getUserProperties(user));
  mixpanel.track("Sign up");
};

export const logout = () => {
  mixpanel.reset();
};

export const viewProfile = (user) => {
  mixpanel.track("View profile", {
    Profile: user.username,
  });
};

export const viewPage = (pageName) => {
  mixpanel.track(`View ${pageName.toLowerCase()} page`);
};

export const land = (query) => {
  let props = undefined;
  if (query) {
    props = {};
    for (const [key, value] of query) {
      props[key] = value;
    }
  }
  mixpanel.track("Land", props);
};

const _getShoeProperties = (shoe) => ({
  Seller: shoe.seller,
  Brand: shoe.brand,
  Icon: shoe.icon,
  Foot: shoe.foot,
  Gender: shoe.gender,
  Size: shoe.size,
  Condition: shoe.condition,
  Price: shoe.price,
  "Num of Images": shoe.images?.length,
});

export const viewShoe = (shoe) => {
  mixpanel.track("View shoe", _getShoeProperties(shoe));
};

export const viewShoeUpload = () => {
  mixpanel.track("View shoe upload");
};

export const abandonShoeUpload = (formState, formData) => {
  mixpanel.track("Abandon shoe upload", {
    ...formState,
    ..._getShoeProperties(formData),
  });
};

export const uploadShoe = (shoe) => {
  mixpanel.track("Upload shoe", _getShoeProperties(shoe));
};

export const editShoe = (shoe) => {
  mixpanel.track("Edit shoe", _getShoeProperties(shoe));
};

export const deleteShoe = (shoe) => {
  mixpanel.track("Delete shoe", _getShoeProperties(shoe));
};

export const viewCheckout = (shoe) => {
  mixpanel.track("View checkout", _getShoeProperties(shoe));
};

export const successfulOrder = (shoe) => {
  mixpanel.track("Successful order", _getShoeProperties(shoe));
};

export const requestShoe = (shoe) => {
  mixpanel.track("Request shoe", _getShoeProperties(shoe));
};

const defaultExport = {
  init,
  identifyUser,
  registerUser,
  logout,
  viewProfile,
  viewPage,
  land,
  viewShoe,
  viewShoeUpload,
  abandonShoeUpload,
  uploadShoe,
  editShoe,
  deleteShoe,
  viewCheckout,
  successfulOrder,
  requestShoe,
};

export default defaultExport;
