import { React, useEffect, useState, useMemo } from "react";
import { styled } from "@mui/system";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  Card,
  CardMedia,
  CircularProgress,
  Backdrop,
  Alert,
  AlertTitle,
} from "@mui/material";
import Analytics from "../../shared/services/analytics";

const Text = styled(Typography)({
  overflowWrap: "break-word",
  fontSize: "16px",
});

const Checkout = ({ shoes, shoeOptions, user, api }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [buyerProtecOpen, setBuyerProtecOpen] = useState(false);
  const [deliveryOpen, setDeliveryOpen] = useState(false);
  const [error, setError] = useState(false);

  const shoe = useMemo(
    () => shoes.filter((shoe) => shoe.id === parseInt(id)).pop(),
    [shoes, id]
  );
  const defaultImage = useMemo(() => {
    return shoe
      ? shoe.images.filter((image) => image.default).pop() || shoe.images[0]
      : null;
  }, [shoe]);

  const calcBuyerProtection = (price) => 0.75 + price * 0.05;
  const to2Dp = (amount) => parseFloat(amount.toFixed(2));

  const DELIVERY_FEE = 2.95;
  let SHOE_PRICE;
  let BUYER_PROTECTION_FEE;
  let TOTAL_PRICE;

  if (shoe) {
    SHOE_PRICE = parseFloat(shoe.price);
    BUYER_PROTECTION_FEE = to2Dp(calcBuyerProtection(SHOE_PRICE));
    TOTAL_PRICE = to2Dp(SHOE_PRICE + DELIVERY_FEE + BUYER_PROTECTION_FEE);
  }

  useEffect(() => {
    if (!user || !api) {
      navigate("/login", {
        state: {
          previousRoute: `/checkout/${id}`,
        },
      });
      return;
    }

    if (shoe === null || shoe.hasOrder || user.id === shoe.seller) {
      navigate("/");
      return;
    }

    setLoading(false);
  }, [user, api, shoe]);

  useEffect(() => {
    Analytics.viewCheckout(shoe);
  }, [shoe]);

  const handleBuy = (event) => {
    event.preventDefault();
    api
      .createCheckoutSession(
        id,
        BUYER_PROTECTION_FEE,
        DELIVERY_FEE,
        TOTAL_PRICE
      )
      .then((res) => {
        Analytics.viewPage("stripe payment");
        window.location.href = res.url;
      })
      .catch((err) => {
        setError(true);
      });
  };

  return (
    <>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          minHeight="80vh"
        >
          <Grid item xs={12} my={2}>
            <Typography variant="body1">Just a second...</Typography>
          </Grid>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Container maxWidth="sm">
          <Typography variant="h3" mt={8}>
            Order summary
          </Typography>
          <Box mt={4}>
            <hr />
            <Grid container mt={2} mb={2}>
              <Grid item xs={6}>
                <Card>
                  <CardMedia
                    component="img"
                    height="200"
                    src={defaultImage?.image}
                    alt="Really cool image here"
                  />
                </Card>
              </Grid>
              <Grid item xs={6} mt={2}>
                <Typography
                  ml={4}
                  sx={{
                    fontWeight: 600,
                    color: "inherit",
                    overflowWrap: "break-word",
                    // textIndent: "6px",
                  }}
                  as={Link}
                  to={`/shoes/${shoe.id}/`}
                >
                  {`${shoeOptions.getBrandLabel(shoe.brand)} ${shoe.icon}`}
                </Typography>
                <Text ml={4} mt={1}>
                  {shoeOptions.getFootLabel(shoe.foot)} shoe
                </Text>
                <Text ml={4} mt={1}>
                  Uk {shoe.size}
                </Text>
              </Grid>
              <Grid item xs={12} mt={4}>
                <Text ml={3} mt={1}>
                  Subtotal: £{SHOE_PRICE}
                </Text>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => setBuyerProtecOpen(true)}>
                  <Text
                    ml={2}
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    <u>Buyer Protection</u>: £{BUYER_PROTECTION_FEE}
                  </Text>
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  }}
                  open={buyerProtecOpen}
                  onClick={() => setBuyerProtecOpen(false)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      backgroundColor: "#edede9",
                      maxWidth: "350px",
                    }}
                    p={4}
                  >
                    <Typography
                      variant="h4"
                      color="secondary"
                      textAlign="center"
                    >
                      Buyer Protection
                    </Typography>
                    <Typography color="primary" textAlign="center">
                      £0.75 + 5% of the shoe's price
                    </Typography>

                    <Typography color="primary" mt={2}>
                      <b>Refund policy</b>
                    </Typography>
                    <Typography color="primary">
                      You can receive a refund if your order:
                    </Typography>
                    <Typography color="primary" ml={2}>
                      • was never shipped or lost
                    </Typography>
                    <Typography color="primary" ml={2}>
                      • arrives damaged
                    </Typography>
                    <Typography color="primary" ml={2}>
                      • is significantly not as described.
                    </Typography>
                    <Typography color="primary" ml={2} mt={2}>
                      You have 2 days to submit your claim from when you’re
                      notified that an item was delivered, even if the item
                      never arrived. Buyers cover the cost of returning an item
                      unless agreed otherwise. Learn more in our Refund Policy.
                    </Typography>
                  </Box>
                </Backdrop>
              </Grid>

              <Grid item xs={12}>
                <Button onClick={() => setDeliveryOpen(true)}>
                  <Text
                    ml={2}
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    <u>Delivery</u>: £{DELIVERY_FEE}
                  </Text>
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  }}
                  open={deliveryOpen}
                  onClick={() => setDeliveryOpen(false)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      backgroundColor: "#edede9",
                      maxWidth: "350px",
                    }}
                    p={4}
                  >
                    <Typography
                      variant="h4"
                      color="secondary"
                      textAlign="center"
                    >
                      Delivery
                    </Typography>
                    <Typography color="primary" textAlign="center">
                      All UK shipping is fixed at £{DELIVERY_FEE}
                    </Typography>

                    <Typography color="primary" mt={2}>
                      <b>Shipping policy</b>
                    </Typography>
                    <Typography color="primary">
                      • Through a partnership with our delivery provider, we
                      have negotiated a fixed shipping price of £{DELIVERY_FEE}{" "}
                      for all UK addresses
                    </Typography>
                    <Typography color="primary">
                      • Items can typically expect to be delivered within 5
                      days.
                    </Typography>
                  </Box>
                </Backdrop>
              </Grid>
              <Text ml={3} mt={1} sx={{ fontWeight: 600, fontSize: "20px" }}>
                Total: £{TOTAL_PRICE}
              </Text>
            </Grid>
            <hr />
          </Box>
          <Typography variant="h4" mt={4}>
            Next steps
          </Typography>
          <Text mt={1}>
            1. Once payment is complete, the seller will be notified to post the
            shoe 📭
          </Text>
          <Text>
            2. You will receive an email update once the shoe has been sent 📧
          </Text>
          <Text>
            3. If the seller doesn't send the shoe within 5 days you will
            automatically be refunded 💵
          </Text>
          <Text>4. Start shredding the new shoe 🛹 </Text>

          <Box mt={4}>
            <form onSubmit={(event) => handleBuy(event)}>
              <Button type="submit" variant="contained" sx={{ width: "100%" }}>
                Pay Now
              </Button>
            </form>

            <Box pt={2}>
              {error && (
                <Alert severity="error" onClose={() => setError(false)}>
                  <AlertTitle>Ah geez</AlertTitle>
                  Something went wrong - please contact us if this issue
                  persists.
                </Alert>
              )}
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};

export default Checkout;
