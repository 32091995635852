import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#201F1F",
    },
    secondary: {
      main: "#70798C",
    },
    accent: {
      main: "#FF5550",
    },
    white: {
      main: "#F5F5F5",
    },
    black: {
      main: "#201F1F",
    },
    grey: {
      main: "#70798C",
    },
    offWhite: {
      main: "#DEE3DE",
    },
    brightWhite: {
      main: "#fdfdfd",
    },
    purple: {
      main: "#9593D9",
    },
    orange: {
      main: "#FF5550",
    },
    orangeHover: {
      main: "#FF2F29",
    },
    background: {
      main: "#F4F6F4",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Manrope",
      color: "#201F1F",
    },
    h1: {
      fontSize: "25px",
      fontWeight: "800",
    },
    h2: {
      fontSize: "22px",
      fontWeight: "800",
    },
    h3: {
      fontSize: "18px",
      fontWeight: "700",
    },
    subtitle1: {
      color: "#70798C",
      fontSize: "18px",
      fontWeight: "700",
    },
    subtitle2: {
      color: "#70798C",
      fontSize: "16px",
      fontWeight: "700",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "normal",
          textTransform: "none",
          color: "#F5F5F5",
          backgroundColor: "#FF5550",
          padding: "11px 50px",
          borderRadius: "20px",
          boxShadow: "none",
          flexGrow: 1,
          ":hover": {
            backgroundColor: "#FF2F29",
          },
        },
        containedSecondary: {
          backgroundColor: "#201F1F",
          ":hover": {
            backgroundColor: "black",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "none",
          borderBottom: "0.6px solid #FF5550",
          height: "60px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "0 !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
        notchedOutline: {
          borderColor: "#70798C",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#fdfdfd",
          borderRadius: "20px",
          border: `1px solid #70798C`,
          boxShadow: "none",
          padding: "18px",
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          padding: "12px",
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
          border: `0.5px solid #DEE3DE`,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: "12px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        },
      },
    },
  },
});

export const useThemeStyles = makeStyles((theme) => ({
  responsiveWidth: {
    width: "calc(100% - 44px)",
    maxWidth: "1000px",
  },
}));

export default theme;
