import React, { useEffect, useState } from "react";
import { Box, Typography, CardContent, Card, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import Form from "../../shared/components/Form";
import Analytics from "../../shared/services/analytics";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 15px 30px",
  },
  card: {
    width: theme.breakpoints.values.sm,
    maxWidth: "95%",
  },
}));

const ShoeUpload = ({
  edit = false,
  user,
  setShoes,
  shoeOptions,
  api,
  shoes,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [unuploadedShoeData, setUnuploadedShoeData] = useState(null);
  const [localStorageChecked, setLocalStorageChecked] = useState(false);
  const [manualErrors, setManualErrors] = useState(null);

  const UNUPLOADED_SHOE_DATA_KEY = "unuploadedShoeData";

  const shoe = edit
    ? shoes.filter((shoe) => shoe.id === parseInt(id)).pop()
    : unuploadedShoeData ?? null;

  useEffect(() => {
    if (edit && (!shoe || shoe?.seller !== user?.id)) {
      navigate("/not-found");
      return;
    }
  });

  useEffect(() => {
    const shoeData = localStorage.getItem(UNUPLOADED_SHOE_DATA_KEY);
    if (shoeData) {
      setUnuploadedShoeData(JSON.parse(shoeData));
    }
    setLocalStorageChecked(true);
  }, []);

  // analytics
  useEffect(() => {
    if (!edit && user && api) {
      Analytics.viewShoeUpload();
    }
  }, []);

  const _storeUnuploadedShoeData = (shoeData) => {
    const toStore = {
      brand: shoeData.brand.value,
      condition: shoeData.condition,
      foot: shoeData.foot.value,
      gender: shoeData.gender.value,
      icon: shoeData.icon,
      images: shoeData.images.map((file) => ({
        image: URL.createObjectURL(file),
      })),
      price: shoeData.price,
      size: shoeData.size.value,
    };
    localStorage.setItem(UNUPLOADED_SHOE_DATA_KEY, JSON.stringify(toStore));
  };

  const uploadShoe = (data) => {
    if (!user || !api) {
      _storeUnuploadedShoeData(data);
      navigate("/register", {
        state: {
          previousRoute: "/sell",
        },
      });
      return;
    }

    if (unuploadedShoeData) {
      localStorage.removeItem(UNUPLOADED_SHOE_DATA_KEY);
    }

    setLoading(true);
    api
      .createShoe(
        user.id,
        data.brand.value,
        data.icon,
        data.foot.value,
        data.gender.value,
        data.size.value,
        data.condition,
        data.price,
        data.images
      )
      .then((shoe) => {
        setLoading(false);
        setShoes((prevShoes) => [...prevShoes, shoe]);
        Analytics.uploadShoe(shoe);
        navigate(`/shoes/${shoe.id}`);
      })
      .catch((errors) => {
        setManualErrors(errors);
        setLoading(false);
      });
  };

  const editShoe = (data) => {
    setLoading(true);
    api
      .editShoe(
        shoe.id,
        data.brand.value,
        data.icon,
        data.foot.value,
        data.gender.value,
        data.size.value,
        data.condition,
        data.price,
        data.images,
        shoe.images
      )
      .then((shoe) => {
        setShoes((prevShoes) =>
          prevShoes.map((prevShoe) =>
            prevShoe.id === shoe.id ? shoe : prevShoe
          )
        );
        Analytics.editShoe(shoe);
        setLoading(false);
        navigate(`/shoes/${shoe.id}`);
      })
      .catch((errors) => {
        setManualErrors(errors);
        setLoading(false);
      });
  };

  const onAbandon = (formState, formData) => {
    Analytics.abandonShoeUpload(formState, { ...formData, seller: user?.id });
  };

  const fields = [
    {
      name: "brand",
      label: "Shoe Brand",
      required: true,
      component: "autocomplete",
      options: shoeOptions?.brand,
      initial: shoe
        ? shoeOptions?.brand
            ?.filter((brand) => brand.value === shoe.brand)
            .pop()
        : null,
    },
    {
      name: "icon",
      label: "Shoe Name",
      required: true,
      initial: shoe ? shoe.icon : null,
    },
    {
      name: "foot",
      label: "Foot",
      required: true,
      component: "autocomplete",
      options: shoeOptions?.foot,
      initial: shoe
        ? shoeOptions?.foot?.filter((foot) => foot.value === shoe.foot).pop()
        : null,
    },
    {
      name: "gender",
      label: "Gender",
      required: true,
      component: "autocomplete",
      options: shoeOptions?.gender,
      initial: shoe
        ? shoeOptions?.gender
            ?.filter((gender) => gender.value === shoe.gender)
            .pop()
        : null,
    },
    {
      name: "size",
      label: "Size (UK)",
      required: true,
      component: "autocomplete",
      options: shoeOptions?.size,
      initial: shoe
        ? shoeOptions?.size?.filter((size) => size.value === shoe.size).pop()
        : null,
    },
    {
      name: "price",
      label: "Asking Price (£)",
      required: true,
      type: "number",
      initial: shoe ? shoe.price : null,
    },
    {
      name: "condition",
      label: "Shoe condition",
      required: true,
      multiline: true,
      initial: shoe ? shoe.condition : null,
    },
    {
      name: "images",
      label: "Images",
      required: true,
      component: "imageUpload",
      dropzoneText: "Images",
      acceptedFiles: ["image/jpeg", "image/png"],
      filesLimit: 6,
      initial: shoe ? shoe.images?.map((image) => image.image) : null,
    },
  ];

  return (
    <div>
      {loading || !localStorageChecked ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          minHeight="80vh"
        >
          <Grid item xs={12} my={2}>
            <Typography variant="body1">Just a second...</Typography>
          </Grid>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Box container className={classes.container}>
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography variant="h6" component="h1" textAlign="center" mb={1}>
                {edit ? "Edit Shoe" : "Sell a shoe 👟"}
              </Typography>
              {edit ? (
                <Typography variant="body1" mt={3} mb={2}>
                  Something wrong? Feel free to update any details below.
                </Typography>
              ) : (
                <Typography variant="body1" mt={3} mb={2}>
                  Upload a shoe to sell. This will create a "For Sale" post,
                  enabling others to view and purchase this shoe. Good pics go a
                  long way 📸
                </Typography>
              )}
              <Form
                fields={fields}
                onSubmit={edit ? editShoe : uploadShoe}
                submitButtonText={edit ? "Update" : "Sell"}
                onAbandon={onAbandon}
                manualErrors={manualErrors}
              />
            </CardContent>
          </Card>
        </Box>
      )}
    </div>
  );
};

export default ShoeUpload;
