class API {
  BASE_URL = process.env.REACT_APP_API_BASE_URL;
  USER_LOCAL_STORAGE_KEY = "user";

  constructor(currentUser, setCurrentUser, setLocalStorageChecked) {
    this.currentUser = currentUser;
    this._setCurrentUser = setCurrentUser;
    this.refreshToken = currentUser?.refresh;
    this.accessToken = currentUser?.access;
    if (!currentUser) {
      this._updateCurrentUserFromLocalStorage();
      setLocalStorageChecked(true);
    }
  }

  setCurrentUser = (currentUser) => {
    if (!currentUser) {
      localStorage.removeItem(this.USER_LOCAL_STORAGE_KEY);
    } else {
      localStorage.setItem(
        this.USER_LOCAL_STORAGE_KEY,
        JSON.stringify(currentUser)
      );
    }

    this._setCurrentUser(currentUser);
  };

  getShoes = async () => {
    const res = await this._fetch("api/shoes/");
    if (res.status !== 200) {
      throw new Error(res.statusText);
    }
    const shoes = this._clean(await res.json());
    return shoes;
  };

  getUsers = async () => {
    const res = await this._fetch("api/users/");
    if (res.status !== 200) {
      throw new Error(res.statusText);
    }
    const users = this._clean(await res.json());
    return users;
  };

  getUser = async (id) => {
    const res = await this._fetch(`api/users/${id}/`);
    if (res.status !== 200) {
      throw new Error(res.statusText);
    }
    const user = this._clean(await res.json());
    return user;
  };

  login = async (username, password) => {
    const res = await this._fetch("api/token/", "POST", { username, password });
    if (res.status !== 200) {
      const err = await res.json();
      throw new Error(err.detail);
    }
    const authTokens = this._clean(await res.json());

    // Temporarily have to set this for the getUser call to pass
    // will be overriden by `this.setCurrentUser(currentUser)` below
    this.accessToken = authTokens.access;
    this.refreshToken = authTokens.refresh;

    const userId = this._parseTokenUserId(authTokens.access);
    const user = await this.getUser(userId);
    const currentUser = { ...user, ...authTokens };
    this.setCurrentUser(currentUser);
    return currentUser;
  };

  logout = () => {
    this.setCurrentUser(null);
  };

  register = async (firstName, lastName, username, email, password) => {
    const res = await this._fetch("api/users/", "POST", {
      firstName,
      lastName,
      username,
      email,
      password,
    });
    if (res.status !== 201) {
      throw new Error(res.statusText);
    }
    const currentUser = await this.login(username, password);
    return currentUser;
  };

  isUsernameValid = async (username) => {
    const res = await this._fetch("api/users/is_username_valid/", "POST", {
      username,
    });
    if (res.status !== 200) {
      throw new Error(res.statusText);
    }
    const isValid = (await res.text()) === "true";
    return isValid;
  };

  getShoeOptions = async () => {
    const res = await this._fetch("api/shoes/", "OPTIONS");
    if (res.status !== 200) {
      throw new Error(res.statusText);
    }
    const options = await res.json();
    const fieldOptions = options.actions.POST;

    const shoeOptions = {};
    Object.keys(fieldOptions).forEach((fieldName) => {
      const field = fieldOptions[fieldName];
      if (field.type === "choice") {
        shoeOptions[fieldName] = this._djangoChoicesToAutocompleteOptions(
          field.choices
        );
      }
    });

    return shoeOptions;
  };

  createShoe = async (
    seller,
    brand,
    icon,
    foot,
    gender,
    size,
    condition,
    price,
    images,
    requested = false
  ) => {
    const res = await this._fetch("api/shoes/", "POST", {
      seller,
      brand,
      icon,
      foot,
      gender,
      size,
      condition,
      price,
      requested,
    });

    if (res.status !== 201) {
      const jsonRes = await res.json();
      const errors = Object.entries(jsonRes).map((err) => ({
        name: err[0],
        message: err[1],
      }));
      throw errors;
    }
    const shoe = this._clean(await res.json());
    shoe.images = await this._createShoeImages(shoe.id, images);
    return shoe;
  };

  createShoeImage = async (shoeId, file, isDefault = false) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("default", isDefault);

    const res = await this._fetch(
      `api/shoes/${shoeId}/images/`,
      "POST",
      formData
    );

    if (res.status !== 201) {
      throw new Error(res.statusText);
    }

    const image = this._clean(await res.json());
    return image;
  };

  editShoe = async (
    shoeId,
    brand,
    icon,
    foot,
    gender,
    size,
    condition,
    price,
    newImages,
    oldImages
  ) => {
    const res = await this._fetch(`api/shoes/${shoeId}/`, "PATCH", {
      brand,
      icon,
      foot,
      gender,
      size,
      condition,
      price,
    });

    if (res.status !== 200) {
      throw new Error(res.statusText);
    }
    const shoe = this._clean(await res.json());
    const shoeImages = await this._editShoeImages(
      shoe.id,
      newImages,
      oldImages
    );
    shoe.images = shoeImages;
    return shoe;
  };

  deleteShoe = async (id) => {
    const res = await this._fetch(`api/shoes/${id}/`, "DELETE");
    if (res.status !== 204) {
      throw new Error(res.statusText);
    }
  };

  deleteShoeImage = async (shoeId, imageId) => {
    const res = await this._fetch(
      `api/shoes/${shoeId}/images/${imageId}/`,
      "DELETE"
    );
    if (res.status !== 204) {
      throw new Error(res.statusText);
    }
  };

  createCheckoutSession = async (
    shoeId,
    buyerProtectionFee,
    deliveryFee,
    totalPrice
  ) => {
    const res = await this._fetch(
      "api/payments/create-checkout-session/",
      "POST",
      {
        shoe_id: shoeId,
        buyer_protection_fee: buyerProtectionFee,
        delivery_fee: deliveryFee,
        total_price: totalPrice,
      }
    );

    if (res.status !== 201) {
      throw new Error(res.statusText);
    }

    return this._clean(await res.json());
  };

  _updateCurrentUserFromLocalStorage = () => {
    if (localStorage.getItem(this.USER_LOCAL_STORAGE_KEY)) {
      this._setCurrentUser(
        JSON.parse(localStorage.getItem(this.USER_LOCAL_STORAGE_KEY))
      );
    }
  };

  _parseTokenData = (token) => {
    const tokenDataPart = token.split(".")[1];
    const tokenData = JSON.parse(atob(tokenDataPart));
    return tokenData;
  };

  _parseTokenExpiry = (token) => {
    const tokenData = this._parseTokenData(token);
    // Date()s in JS are miliseconds since 1970
    // rather than seconds hence the *1000 below
    return new Date(tokenData.exp * 1000);
  };

  _parseTokenUserId = (token) => {
    const tokenData = this._parseTokenData(token);
    return tokenData.user_id;
  };

  _hasTokenExpired = (token) => {
    const expiry = this._parseTokenExpiry(token);
    const now = new Date();
    return now > expiry;
  };

  _refreshAccessToken = async () => {
    const res = await this._fetch(
      "api/token/refresh/",
      "POST",
      { refresh: this.refreshToken },
      // This flag allows us to bypass the auth headers so
      // that we don't end up in an infinite loop
      false
    );

    if (res.status !== 200) {
      throw new Error(res.statusText);
    }

    const { access } = this._clean(await res.json());

    this.accessToken = access;
    this.setCurrentUser({ ...this.currentUser, access });
  };

  _addAuthHeaders = async (headers) => {
    if (this.accessToken) {
      if (this._hasTokenExpired(this.accessToken)) {
        if (!this._hasTokenExpired(this.refreshToken)) {
          await this._refreshAccessToken();
        } else {
          this.logout();
          return;
        }
      }
      headers.append("Authorization", `Bearer ${this.accessToken}`);
    }
  };

  _fetch = async (path, method = "GET", data, useAuth = true) => {
    // Creates a wrapper around fetch that deals with:
    //  1. Creating a full URL from the path
    //  2. Adding auth headers and updating them if expired
    //  3. Converting POST data to use snake case rather than camel
    //  4. Converting POST data to JSON and adding the content-type header

    if (method === "POST" && !data) {
      throw new Error("No data passed for POST request");
    }

    const headers = new Headers();

    if (useAuth) {
      await this._addAuthHeaders(headers);
    }

    let body;

    if (data) {
      if (data instanceof FormData) {
        body = data;
      } else {
        // We can assume that it is a dictionary in this case
        headers.append("Content-Type", "application/json");
        body = JSON.stringify(this._cleanForAPI(data));
      }
    }

    const res = await fetch(this._getURL(path), {
      method,
      headers,
      body,
    });

    return res;
  };

  _getURL = (path) => {
    const url = new URL(path, this.BASE_URL);
    return url.toString();
  };

  _toCamelCase = (str) => {
    return str.replace(/([_][a-z])/g, (captured) => {
      return captured?.toUpperCase().replace("_", "");
    });
  };

  _toSnakeCase = (str) => {
    return str.replace(/([a-z][A-Z])/g, (captured) => {
      const lowerCaseStr = captured?.toLowerCase();
      return `${lowerCaseStr.slice(0, 1)}_${lowerCaseStr.slice(1)}`;
    });
  };

  _clean = (data, forAPI = false) => {
    const cleanFunc = forAPI ? this._toSnakeCase : this._toCamelCase;

    const cleanKeys = (obj) => {
      const cleanedObj = {};
      Object.keys(obj).forEach((key) => {
        if (
          !Array.isArray(obj[key]) &&
          typeof obj[key] === "object" &&
          obj[key] != null
        ) {
          const cleanedValue = this._clean(obj[key]);
          cleanedObj[cleanFunc(key)] = cleanedValue;
        } else {
          cleanedObj[cleanFunc(key)] = obj[key];
        }
      });
      return cleanedObj;
    };

    if (Array.isArray(data) && data.length > 0 && typeof data[0] === "object") {
      // Non-empty array of objects
      return data.map((obj) => cleanKeys(obj));
    } else if (!Array.isArray(data) && typeof data === "object") {
      // An object
      return cleanKeys(data);
    } else {
      // Everything else
      return data;
    }
  };

  _cleanForAPI = (data) => this._clean(data, true);

  _djangoChoicesToAutocompleteOptions = (fieldOptions) => {
    return fieldOptions.map((option) => ({
      value: option.value,
      label: option.display_name,
    }));
  };

  _createShoeImages = async (shoeId, imageFiles) => {
    const images = [];
    for (let i = 0; i < imageFiles.length; i++) {
      const imageFile = imageFiles[i];
      const image = await this.createShoeImage(shoeId, imageFile, i === 0);
      images.push(image);
    }
    return images;
  };

  _deleteShoeImages = async (shoeId, images) => {
    for (let image of images) {
      await this.deleteShoeImage(shoeId, image.id);
    }
  };

  _editShoeImages = async (shoeId, newImages, oldImages) => {
    await this._deleteShoeImages(shoeId, oldImages);
    return await this._createShoeImages(shoeId, newImages);
  };
}

export default API;
