import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Button,
  Autocomplete,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";
import { DropzoneArea } from "material-ui-dropzone";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    marginTop: 10,
    border: "solid 1px rgba(0, 0, 0, 0.22) !important",
  },
  dropzoneError: {
    marginTop: 10,
    border: `solid 1px ${theme.palette.error.main} !important`,
  },
  dropzoneText: {
    color: theme.palette.text.secondary,
  },
  dropzoneTextError: {
    color: theme.palette.error.main,
  },
}));

const Form = ({
  fields,
  onSubmit,
  submitButtonText,
  onAbandon,
  manualErrors,
}) => {
  const classes = useStyles();

  // HACK: this is due to a bug in the dropzone code where onChange
  // is run even though nothing has changed yet.
  // Issue: https://github.com/Yuvaleros/material-ui-dropzone/issues/237
  const [dzFirstOnChangeRan, setDzFirstOnChangeRan] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    control,
    formState,
    setValue,
    getValues,
  } = useForm();

  const { errors } = formState;

  useEffect(() => {
    if (manualErrors && manualErrors.length > 0) {
      manualErrors.forEach((error) => {
        setError(error.name, { type: "manual", message: error.message });
      });
    }
  }, [manualErrors, setError]);

  // TODO: Please find a less ugly solution to this :cry: (Needed for Analytics)
  const formStateRef = useRef({});
  useEffect(() => {
    formStateRef.current = {
      isDirty: formState.isDirty,
      dirtyFields: formState.dirtyFields,
      touchedFields: formState.touchedFields,
      isSubmitted: formState.isSubmitted,
      submitCount: formState.submitCount,
      isValid: formState.isValid,
      errors: formState.errors,
    };
  }, [
    formState.isDirty,
    Object.keys(formState.dirtyFields).length,
    Object.keys(formState.touchedFields).length,
    formState.isSubmitted,
    formState.submitCount,
    formState.isValid,
    Object.keys(formState.errors).length,
  ]);

  useEffect(() => {
    // Only has a cleanup for when the form is abandoned
    return () => {
      if (onAbandon && !formStateRef.current.isSubmitSuccessful) {
        onAbandon(formStateRef.current, getValues());
      }
    };
  }, [onAbandon, getValues]);

  const getFieldValidation = (field) => ({
    required: {
      value: field.required,
      message: "This field is required to continue",
    },
    validate: field.validate ? (v) => field.validate(v, watch()) : undefined,
  });

  const renderTextField = (field, controlled, extraParams) => (
    <TextField
      {...extraParams}
      // controlled tells us whether the TextField should
      // be registered or if it's parent is controlling it
      {...(controlled ? {} : register(field.name, getFieldValidation(field)))}
      key={field.name}
      label={field.label}
      type={field.type}
      multiline={field.multiline}
      rows={field.multiline ? 4 : 1}
      error={errors[field.name] ? true : false}
      helperText={errors[field.name]?.message}
      margin="dense"
      fullWidth
      defaultValue={controlled ? undefined : field.initial}
    />
  );

  const renderAutocomplete = (field, extraParams) => (
    <Controller
      name={field.name}
      key={field.name}
      control={control}
      defaultValue={field.initial}
      rules={getFieldValidation(field)}
      render={(controller) => {
        return (
          <Autocomplete
            {...extraParams}
            {...controller.field}
            // Override the react-hook form field onChange
            onChange={(e, option) => controller.field.onChange(option)}
            options={field.options}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={(params) => renderTextField(field, true, params)}
          />
        );
      }}
    />
  );

  const renderDropzoneArea = (field, extraParams) => (
    <Box key={field.name}>
      <DropzoneArea
        {...extraParams}
        {...register(field.name, getFieldValidation(field))}
        acceptedFiles={field.acceptedFiles}
        filesLimit={field.filesLimit}
        dropzoneText={field.dropzoneText}
        initialFiles={field.initial || undefined}
        maxFileSize={10 * 1024 * 1024}
        // Override the react-hook form field onChange
        onChange={(files) => {
          const processedFiles = files.map((file) => {
            const fileComponents = file.name.split(".");
            if (fileComponents.length === 1) {
              const fileNameWithFormat = `${file.name}.${
                file.type.split("/")[1]
              }`;
              return new File([file], fileNameWithFormat);
            }
            return file;
          });
          setValue(field.name, processedFiles, {
            shouldValidate: dzFirstOnChangeRan,
          });
          if (!dzFirstOnChangeRan) {
            setDzFirstOnChangeRan(true);
          }
        }}
        dropzoneClass={
          errors[field.name] ? classes.dropzoneError : classes.dropzone
        }
        dropzoneParagraphClass={
          errors[field.name] ? classes.dropzoneTextError : classes.dropzoneText
        }
      />

      {field.extraInfo && (
        <Typography color="orange">
          *Hint - Upload an image from the internet if you don't have any.
        </Typography>
      )}

      {errors[field.name] ? (
        <Typography variant="caption" color="error" ml={"14px"}>
          {errors[field.name]?.message}
        </Typography>
      ) : null}
    </Box>
  );

  const renderInput = (field) => {
    switch (field.component) {
      case "autocomplete":
        return renderAutocomplete(field);

      case "imageUpload":
        return renderDropzoneArea(field);

      default:
        return renderTextField(field);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field) => renderInput(field))}
      <Button
        type="submit"
        variant="contained"
        fullWidth
        size="large"
        sx={{ marginTop: 3 }}
      >
        {submitButtonText}
      </Button>
    </form>
  );
};

export default Form;
