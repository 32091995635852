import React from "react";
import { Button, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { IconButton } from "@material-ui/core";
import { Facebook, Instagram } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    bottom: 0,
    paddingBottom: "15px",
    // position: "absolute",
  },
  socialIcons: {
    padding: "4px !important",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box>
        <Button href="mailto: switchshoes.info@gmail.com?subject=Question from 'contact us'">
          Got a question? Get in touch!
        </Button>
      </Box>
      <Box>
        <IconButton
          className={classes.socialIcons}
          href="https://www.facebook.com/SkateSwitchShoes"
        >
          <Facebook fontSize="large" />
        </IconButton>
        <IconButton
          className={classes.socialIcons}
          href="https://www.instagram.com/switchshoesskateboarding/"
        >
          <Instagram fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;
