import React, { useState, useEffect } from "react";
import { Box, Card, Typography, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "../../shared/components/Form";
import Analytics from "../../shared/services/analytics";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 15px 30px",
  },
  card: {
    maxWidth: "400px",
  },
}));

function Login({ api }) {
  const { state } = useLocation();

  const fields = [
    { name: "username", label: "Username", required: true },
    { name: "password", label: "Password", required: true, type: "password" },
  ];
  const classes = useStyles();
  const navigate = useNavigate();
  const [fieldErrors, setFieldErrors] = useState([]);
  const unsetFieldErrors = () => setFieldErrors([]);

  useEffect(() => {
    Analytics.viewPage("login");
  }, []);

  const handleLogin = (data) => {
    unsetFieldErrors();
    api
      .login(data.username, data.password)
      .then((user) => {
        state?.previousRoute
          ? navigate(state.previousRoute, {
              state,
            })
          : navigate("/account");
      })
      .catch((err) => {
        setFieldErrors([
          ...fieldErrors,
          {
            name: "password",
            message: err.message,
          },
        ]);
      });
  };

  return (
    <Box container className={classes.container}>
      <Card variant="outlined" className={classes.card}>
        <CardContent>
          <Typography variant="h6" component="h1" textAlign="center" mb={2}>
            Login to your account
          </Typography>
          <Form
            fields={fields}
            onSubmit={handleLogin}
            submitButtonText="Login"
            manualErrors={fieldErrors}
          />
          <Typography variant="body1" component="div" align="center" mt={2}>
            Don't have an account? Register <Link to="/register">here</Link>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Login;
