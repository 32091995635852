import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography, CardContent, Card, Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Form from "../../shared/components/Form";
import Analytics from "../../shared/services/analytics";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 15px 30px",
  },
  card: {
    width: theme.breakpoints.values.sm,
    maxWidth: "95%",
  },
}));

const RequestAShoe = ({ user, setShoes, shoeOptions, api, edit = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [manualErrors, setManualErrors] = useState(null);

  const { state } = useLocation();
  const shoe = state?.shoeData;

  useEffect(() => {
    const shoeData = state?.shoeData;
    if (shoeData) {
      uploadShoe(shoeData);
    }
  }, [state]);

  useEffect(() => {
    Analytics.viewPage("request shoe");
  }, []);

  const fields = [
    {
      name: "brand",
      label: "Shoe Brand",
      required: true,
      component: "autocomplete",
      options: shoeOptions?.brand,
      initial: shoe ? shoe.brand : null,
    },
    {
      name: "icon",
      label: "Shoe Name",
      required: true,
      initial: shoe ? shoe.icon : null,
    },
    {
      name: "foot",
      label: "Foot",
      required: true,
      component: "autocomplete",
      options: shoeOptions?.foot,
      initial: shoe ? shoe.foot : null,
    },
    {
      name: "gender",
      label: "Gender",
      required: true,
      component: "autocomplete",
      options: shoeOptions?.gender,
      initial: shoe ? shoe.gender : null,
    },
    {
      name: "size",
      label: "Size (UK)",
      required: true,
      component: "autocomplete",
      options: shoeOptions?.size,
      initial: shoe ? shoe.size : null,
    },
    {
      name: "price",
      label: "Price willing to pay (£)",
      required: true,
      type: "number",
      initial: shoe ? shoe.price : null,
    },
    {
      name: "condition",
      label: "Shoe condition",
      required: true,
      multiline: true,
      initial: shoe ? shoe.condition : null,
    },
    {
      name: "images",
      label: "Images",
      required: true,
      component: "imageUpload",
      acceptedFiles: ["image/jpeg", "image/png"],
      dropzoneText: "Images",
      filesLimit: 6,
      initial: shoe ? shoe.images : null,
      extraInfo: true,
    },
  ];

  const uploadShoe = (data) => {
    if (!user || !api) {
      navigate("/register", {
        state: {
          previousRoute: "/request-shoe",
          shoeData: data,
        },
      });
      return;
    }

    setLoading(true);
    api
      .createShoe(
        user.id,
        data.brand.value,
        data.icon,
        data.foot.value,
        data.gender.value,
        data.size.value,
        data.condition,
        data.price,
        data.images,
        true
      )
      .then((shoe) => {
        setLoading(false);
        setShoes((prevShoes) => [...prevShoes, shoe]);
        Analytics.requestShoe(shoe);
        navigate(`/shoes/${shoe.id}`);
      })
      .catch((errors) => {
        setManualErrors(errors);
        setLoading(false);
      });
  };

  return (
    <div>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          minHeight="80vh"
        >
          <Grid item xs={12} my={2}>
            <Typography variant="body1">Just a second...</Typography>
          </Grid>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Box container className={classes.container}>
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography variant="h6" component="h1" textAlign="center" mb={1}>
                Request a shoe 👟
              </Typography>
              <Typography variant="body1" mt={3} mb={2}>
                Enter the details of the shoe you're searching for here. In
                addition to creating a "Wanted" post, we will also{" "}
                <u>personally</u> try and locate this shoe for you!
              </Typography>
              <Form
                fields={fields}
                onSubmit={uploadShoe}
                submitButtonText={"Request"}
                manualErrors={manualErrors}
                // onAbandon={onAbandon}
              />
            </CardContent>
          </Card>
        </Box>
      )}
    </div>
  );
};

export default RequestAShoe;
